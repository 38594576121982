@media print {
  .header,
  .footer,
  .print,
  h1 {
    display: none !important;
  }

  .content {
    display: block !important;
    flex: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .label-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 210mm !important;
    page-break-after: always;
  }
}
