@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  unicode-range: U+0020-024F; /* Latin + Latin extended */
  src: url('../fonts/poppins/poppins-v20-latin-ext_latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins/poppins-v20-latin-ext_latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins/poppins-v20-latin-ext_latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* poppins-600 - latin + latin-extended */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  unicode-range: U+0020-024F; /* Latin + Latin extended */
  src: url('../fonts/poppins/poppins-v20-latin-ext_latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins/poppins-v20-latin-ext_latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins/poppins-v20-latin-ext_latin-600.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* poppins-800 - latin + latin-extended */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  unicode-range: U+0020-024F; /* Latin + Latin extended */
  src: url('../fonts/poppins/poppins-v20-latin-ext_latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/poppins/poppins-v20-latin-ext_latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/poppins/poppins-v20-latin-ext_latin-700.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  unicode-range: U+0020-024F; /* Latin + Latin extended */
  src: url('../fonts/mark-pro/MarkPro-Book.woff2') format('woff2'),
    url('../fonts/mark-pro/MarkPro-Book.woff') format('woff');
}

/* poppins-600 - latin + latin-extended */
@font-face {
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  unicode-range: U+0020-024F; /* Latin + Latin extended */
  src: url('../fonts/mark-pro/MarkPro-Bold.woff2') format('woff2'),
    url('../fonts/mark-pro/MarkPro-Bold.woff') format('woff');
}
