@import '../../scss/styles';

.item {
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 12px 24px;
  border-radius: 12px;
  background-color: #f0efeb;

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .category {
      font-family: $font-mark-pro;
      padding: 4px 8px;
      background: $neutral-50;
      width: fit-content;
      color: $neutral-10;
      font-size: $font-sm;
      border-radius: 4px;
    }

    .title {
      font-family: $font-poppins;
      font-size: $font-lg;
      font-weight: 600;
    }

    .sku {
      font-family: $font-poppins;
      font-size: $font-md;
      font-weight: 600;
      color: $neutral-70;
    }
  }
}
