.search-bar {
  display: flex;
  gap: 8px;
  width: 100%;

  .input-wrapper {
    width: 100%;
  }

  button {
    width: min-content;
    height: min-content;
  }
}
