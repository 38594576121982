.footer {
  display: flex;
  justify-content: center;
  padding: 24px 16px;
  background-color: black;

  &__copyright {
    color: white;
    font-size: 14px;
    text-align: center;
  }
}
