// sizes
$font-sm: 12px;
$font-md: 14px;
// proposed sizes:
$font-lg: 18px;
$font-xl: 24px;
$font-xxl: 32px;

// font families
$font-poppins: 'Poppins', 'arial', 'sans-serif';
$font-mark-pro: 'Mark Pro', 'arial', 'sans-serif';

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 600 !default;
