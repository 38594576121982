@import '../scss/styles';

@media print {
  .label {
    display: flex;
    flex-direction: column;
    padding: 12mm 12mm 8mm 12mm;
    width: 105mm !important;
    height: 148.5mm !important;
    box-sizing: border-box;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 1mm;

      &__name {
        font-family: $font-mark-pro;
        font-size: 25pt;
        line-height: 25pt;
        font-weight: 600;
      }
      &__description {
        font-family: $font-mark-pro;
        font-size: 9pt;
        line-height: 11pt;
        font-weight: 600;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding-top: 4mm;
      gap: 4mm;

      .group {
        display: flex;
        flex-direction: column;
      }

      .multiline {
        display: flex;
        flex-direction: column;
      }

      .singleline {
        display: block;
        line-height: 8pt;
        .title {
          margin-right: 1mm;
        }
      }

      .title {
        font-family: $font-mark-pro;
        font-size: 8pt;
        line-height: 8pt;
        font-weight: 600;
      }

      .description {
        font-family: $font-mark-pro;
        font-size: 8pt;
        line-height: 8pt;
        font-weight: 400;
      }
    }

    &__footer {
      display: flex;
      flex: 1;
      justify-content: space-between;

      &__shipping {
        display: flex;
        align-items: end;
        flex: 1;
        font-family: $font-mark-pro;
        font-size: 6pt;
        line-height: 7pt;
        font-weight: 600;
        max-width: 36mm;
      }

      &__prices {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
        gap: 1mm;

        .price {
          font-family: $font-mark-pro;
          font-size: 17pt;
          line-height: 17pt;
          font-weight: 600;
        }

        .discount {
          font-family: $font-mark-pro;
          font-size: 11pt;
          line-height: 11pt;
          font-weight: 400;
          text-decoration: line-through;
          color: #575756;
        }

        .ecopart {
          font-family: $font-mark-pro;
          font-size: 7pt;
          line-height: 7pt;
          font-weight: 400;
          color: #575756;
        }
      }
    }

    &__logo {
      align-self: center;
      padding-top: 4mm;
      width: 25mm;
      height: 4mm;
    }
  }
}
