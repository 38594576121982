@import '../../scss/styles';

@media screen {
  .print-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;

    &__title {
      margin: 0;
      text-align: center;
      font-family: $font-poppins;
    }

    &__error {
      color: $red;
      font-size: $font-md;
      font-family: $font-poppins;
    }

    &__empty {
      font-size: $font-md;
      font-family: $font-poppins;
      color: $neutral-70;

      a {
        color: black;
      }
    }

    button {
      background: black;
    }
  }
}
