// Primary
$primary: #ff3d3a !default;

// Neutral
$neutral-00: #ffffff !default;
$neutral-10: #f7f6f4 !default;
$neutral-20: #f0efeb !default;
$neutral-30: #d7d6d2 !default;
$neutral-40: #bdbcb7 !default;
$neutral-50: #a1a19b !default;
$neutral-60: #888882 !default;
$neutral-70: #6c6c66 !default;
$neutral-80: #53534c !default;
$neutral-90: #383831 !default;
$neutral-100: #1a1a14 !default;

// Feedback
$green-light: #eefcf6 !default;
$green: #01a862 !default;
$green-dark: #01751e !default;
$red-light: #feecef !default;
$red: #e20430 !default;
$red-dark: #a40928 !default;
$yellow-light: #fff3cd !default;
$yellow: #f6be45 !default;
$yellow-dark: #944d02 !default;
$blue-light: #e9eefb !default;
$blue: #2b5cdc !default;
$blue-dark: #17388c !default;

// Promotional
$promo-primary: #d93549 !default;
$promo-secondary: #f0efeb !default;
$cyber-monday: #01363f !default;

// Third party
$squra: #009c5c !default;

// Overlay
$overlay-50: $neutral-100 50% rgba($neutral-100, 0.5) !default;

// Effects
$hover-neutral: $neutral-10;
