@import '../scss/styles';

@media screen {
  .label {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 8px;
    gap: 12px;

    &__header {
      display: flex;
      flex-direction: column;

      &__name {
        font-family: $font-mark-pro;
        font-size: 40px;
        font-weight: 600;
      }
      &__description {
        font-family: $font-mark-pro;
        font-size: 20px;
        font-weight: 600;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .group {
        display: flex;
        flex-direction: column;
      }

      .multiline {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .singleline {
        display: block;

        .title {
          margin-right: 4px;
        }
      }

      .title {
        font-family: $font-mark-pro;
        font-size: 16px;
        font-weight: 600;
      }

      .description {
        font-family: $font-mark-pro;
        font-size: 16px;
        font-weight: 400;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      flex: 1;

      &__shipping {
        display: flex;
        align-items: end;
        flex: 1;
        max-width: 268px;
        font-family: $font-mark-pro;
        font-size: 16px;
        font-weight: 600;
      }

      &__prices {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;

        .price {
          font-family: $font-mark-pro;
          font-size: 32px;
          font-weight: 600;
        }

        .discount {
          font-family: $font-mark-pro;
          font-size: 20px;
          font-weight: 400;
          text-decoration: line-through;
          color: $neutral-80;
        }

        .ecopart {
          font-family: $font-mark-pro;
          font-size: 14px;
          font-weight: 400;
          color: $neutral-80;
        }
      }
    }

    &__logo {
      align-self: center;
      padding-top: 8px;
      width: 128px;
    }
  }
}
