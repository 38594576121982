@import '../../scss/styles';

.search-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: $font-poppins;
  align-items: center;
  max-width: 768px;

  @include media('sm') {
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin: 0;
    text-align: center;
    font-family: $font-poppins;
  }

  &__subtitle {
    font-family: $font-poppins;
    color: $neutral-70;
  }

  &__no-results,
  &__error {
    color: $red;
    font-size: $font-md;
    font-family: $font-poppins;
  }
}
