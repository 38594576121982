@import '../../scss/styles';

.print-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  font-family: $font-poppins;

  a {
    align-self: center;
    text-decoration: none;
    .button {
      background: black;
      width: auto;
    }
  }

  &__title {
    margin: 0;
    text-align: center;
    font-family: $font-poppins;
  }

  &__subtitle {
    font-family: $font-poppins;
    color: $neutral-70;
    max-width: 768px;
    text-align: center;

    a {
      color: black;
      text-decoration: underline;
    }
  }

  &__error {
    color: $red;
    font-family: $font-poppins;
  }

  &__empty {
    font-family: $font-poppins;
    color: $neutral-70;

    a {
      color: black;
      text-decoration: underline;
    }
  }

  &__list {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 512px));
    gap: 16px;
    width: 100%;
  }
}
