.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: black;
  padding: 16px;
  z-index: 100;

  &__logo {
    width: 146px;
    height: 24px;
  }

  &__print {
    position: relative;

    &__bullet {
      position: absolute;
      width: 10px;
      height: 10px;
      background: orange;
      border-radius: 50%;
      bottom: 0;
      border: 2px solid black;
    }
  }
}
